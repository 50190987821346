import { useState, useEffect, useRef, createContext } from "react"
import cx from "classnames"
import { getSongVersionColor, sanitizedTitle, setExclusivityMessage } from "../utils/browse"
import SongVersionActionsMenu from "./SongVersionActionsMenu"
import SongVersionArc from "./SongVersionArc"
import PartialExclusivityTooltip from "./PartialExclusivityTooltip"
import PlayLargeIcon from "../../icons/PlayLargeIcon"
import CloseIcon from "../../icons/CloseIcon"
import { escapeToClose } from "../utils/helpers"
import { playSong } from "../utils/backbone/playerProxyService"
import { fetchDataForMessagePopUp } from "../utils/backbone/messageQueueService"
import { internalRoles } from "../utils/helpers"
import { addToCart } from "../utils/backbone/drawerProxyService"

//TODO: Later ticket, restructor SongVersionItem or even SongItemBeta, props should be moved to a larger context
//TODO: Move this up a layer so when you unhide a song, all version display as unhidden, not just top one
export const SongVersionItemContext = createContext()

const SongVersionItem = ({
  sv,
  artist,
  song_versions_count,
  song,
  topSongVersion,
  setAltVersionsVisibility,
  user_role,
  songColor,
  handleSetExpandedSongVersionId,
  expandedSongVersionId,
  handleUpdateChildCounter,
  expectedChildLength,
  currentBlanketDealModalRef,
  mixtape = null,
}) => {
  const [expanded, setExpanded] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const [isHidden, setIsHidden] = useState(false)
  const show_alt_versions = topSongVersion && song_versions_count > 1
  const is_internal = internalRoles.includes(user_role)
  const internal_or_ip = is_internal || user_role == "international_partner"
  const shouldShowTooltip = !sv.preRelease && (song.exclusive || sv.vintage)
  const exclusivityMessage = setExclusivityMessage(sv.vintage, song.exclusive)

  let tooltipNudge = mixtape !== null && mixtape.collaborative_in_some_way ? "mixtape-nudge" : ""

  const handlePlaySong = () => {
    const songVersionData = JSON.parse(sv.playSerializer)
    // If mobile audio element is already playing this song, pause it
    var audioElement = $(`audio[src^="/song_versions/play/${songVersionData.id}"]`)
    if (audioElement.length > 0) {
      if (!audioElement[0].paused) {
        audioElement[0].pause()
        return
      }
    }
    playSong(songVersionData)
  }

  const handleLicenseHistory = () => {
    fetchDataForMessagePopUp(`/song_versions/past_licenses/${sv.id}`)
  }

  const handleLicenseClick = () => {
    const songVersionData = JSON.parse(sv.playSerializer)
    // put the song version in session storage regardless of if it's click or custom only
    sessionStorage.setItem("customRequestData", sv.playSerializer)
    if (sv.availableForClick) {
      addToCart(songVersionData)
    } else {
      mm.router.navigate("/licensing/custom-request", { trigger: true })
    }
  }

  const handleRemoveTrack = () => {
    mixtape.removeSongFromMixtape(sv.id)
  }

  // Unexpands any menus for other song versions
  useEffect(() => {
    if (expandedSongVersionId === sv.id) {
      setExpanded(true)
    } else {
      setExpanded(false)
    }
  }, [expandedSongVersionId, sv.id])

  useEffect(() => {
    // Attach the songVersion object and actions
    setIsHidden(
      mm.user.hiddenSongs.hasOwnProperty(song.songId) &&
        mm.user.hiddenSongs[song.songId]["hidden"] === true
    )
    return escapeToClose(setOpenModal)
  }, [])

  const contextValue = {
    isHidden,
    setIsHidden,
    currentBlanketDealModalRef,
  }

  return (
    <li
      className={cx(
        "song-version-item",
        isHidden ? "hidden" : "",
        mixtape && sv.archived && "archived"
      )}
      data-song-version-id={sv.id}
      data-cy="song-version-item"
      style={{ backgroundColor: getSongVersionColor(songColor, user_role, sv) }}
      data-song-version={sv.playSerializer}
      id={sv.id}
    >
      <div itemScope itemType="http://schema.org/MusicRecording">
        <ol className="default over">
          <li
            itemProp="audio"
            itemScope
            itemType="http://schema.org/AudioObject"
            className="action play"
            data-action="play"
            data-cy="play"
            onClick={handlePlaySong}
          >
            <a
              data-prevent-default
              className="album-art-play"
              aria-label="play song button"
              style={{
                backgroundImage: `url(${sv.playerArt})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
              }}
            >
              <span className="play-icon">
                <PlayLargeIcon fill="#21928e" />
              </span>
            </a>
          </li>

          <li className="song-version">
            <div className="song-version-cell">
              <h4 className="title-and-artist">
                <a
                  className="song-version-title"
                  itemProp="url"
                  data-bypass="true"
                  data-cy="song-version-title"
                  href={`/browse/${sv.id}-${sanitizedTitle(sv.displayName)}`}
                >
                  {sv.displayName}
                </a>
                <span itemProp="byArtist" itemScope="" itemType="http://schema.org/MusicGroup">
                  <a
                    className="song-version-artist"
                    data-bypass="true"
                    href={`/artists/${artist.slug}`}
                  >
                    <span itemProp="name">
                      <em>by </em>
                      {artist.name}
                    </span>
                  </a>
                </span>
              </h4>
              <div className="versions-and-licenses">
                {show_alt_versions && (
                  <div className="song-version-badges">
                    <a className="tooltip" onClick={() => setAltVersionsVisibility()}>
                      <mark className="versions">
                        <span className="pill">{song_versions_count - 1}</span>
                      </mark>
                    </a>
                  </div>
                )}
                {song.hasPastLicenses && internal_or_ip && (
                  <div
                    className={cx("action past-licenses", is_internal ? "" : "hidden")}
                    data-action="pastLicenses"
                  >
                    <mark className="licenses" onClick={handleLicenseHistory}>
                      <a className="tooltip" data-prevent-default>
                        L
                      </a>
                    </mark>
                  </div>
                )}
                {sv.hasStems && (
                  <div className={cx("action stems", is_internal ? "" : "hidden")}>
                    <mark className="stems">S</mark>
                  </div>
                )}
                {sv.isNew && (
                  <div className="new-song-pill-wrapper">
                    <mark className="new">
                      <span className="pill">NEW</span>
                    </mark>
                  </div>
                )}
              </div>
            </div>
          </li>

          <li className="genres desktop">
            <span>{sv.joinedGenreList}</span>
          </li>

          <SongVersionArc arc={sv.arc} />

          <li className="energy desktop" data-touch>
            <span>{sv.energy}</span>
          </li>

          <li className="length">{sv.length}</li>
          <SongVersionItemContext.Provider value={contextValue}>
            <SongVersionActionsMenu
              expanded={expanded}
              shouldShowTooltip={shouldShowTooltip}
              sv={sv}
              exclusive={song.exclusive}
              exclusivityMessage={exclusivityMessage}
              user_role={user_role}
              song={song}
              mixtape={mixtape}
            />
          </SongVersionItemContext.Provider>

          <li data-cy="song-actions" className="expand-actions-menu">
            <span
              className={cx("vertical-ellipsis", { ["expanded"]: expanded })}
              onClick={() => {
                !expanded
                  ? handleSetExpandedSongVersionId(sv.id)
                  : handleSetExpandedSongVersionId(false)
              }}
            >{`\u22EE`}</span>
          </li>

          {shouldShowTooltip && (
            <PartialExclusivityTooltip
              tooltipType="license-tooltip"
              vintage={sv.vintage}
              exclusive={song.exclusive}
              action="license"
              cy="license-partial-exclusivity-tooltip"
              exclusivityMessage={exclusivityMessage}
              show={showTooltip}
              nudge={tooltipNudge}
            />
          )}

          {!sv.availableForClick ? (
            <li
              className="action license custom-only"
              data-action="license"
              data-cy="custom-license"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              onClick={() => handleLicenseClick()}
            >
              <a data-prevent-default>
                <span className="button-text">
                  Custom
                  <br />
                  License
                </span>
              </a>
            </li>
          ) : (
            <li
              className="action license"
              data-action="license"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              onClick={() => handleLicenseClick()}
            >
              <a data-prevent-default data-cy="buy-license">
                <span className="button-text">
                  Buy
                  <br />
                  License
                </span>
              </a>
            </li>
          )}
          {mixtape !== null && <li className="collaborator desktop">{sv.collaborator}</li>}
        </ol>
        {mixtape && sv.archived && (
          <div className="archived-message">
            <div className="title-artist">
              <b>{sv.displayName}</b> by <b>{artist.name}</b> is no longer available.
            </div>
            {mixtape.is_owner && (
              <div className="remove-archived-message" onClick={handleRemoveTrack}>
                <CloseIcon />
              </div>
            )}
          </div>
        )}
      </div>
    </li>
  )
}

export default SongVersionItem
